interface SearchProps {
  setSearchTerm: (search: string) => void
  handlePageChange: (page: number) => void
  hasWhiteBackground?: boolean
}

const EntityListSearch = ({setSearchTerm, handlePageChange, hasWhiteBackground = false}: SearchProps) => {
  const handleSearch = (event: any) => {
    if (event.target.value === '') {
      setSearchTerm('')
    }

    if (event.keyCode === 13) {
      handlePageChange(1)
      setSearchTerm(event.target.value)
    }
  }
  return (
    <div className={`box h-40px bg-white ${
      hasWhiteBackground ? 'bg-white' : ''
    }`}>
      <i className="fa fa-search fs-7" aria-hidden="true" />
      <input
        type="text"
        placeholder="Search..."
        role="search"
        onKeyUp={(event) => handleSearch(event)}
        className="fs-7 text-color-gray"
      />
    </div>
  )
}

export default EntityListSearch
