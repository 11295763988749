import React from 'react'
import {Routes, Route, Navigate} from 'react-router'
import {
  Dashboard,
  FinancierPagesWrapper,
  Calls,
  Deals,
  Settings,
  Pipeline,
} from '../features/financiers/pages'
import {useAuth} from 'react-oidc-context'
import CandidatesDetailPage from '../features/financiers/pages/CandidatesDetailPage'
import CallsDetailsPage from '../features/financiers/pages/CallsDetails'

const FinancierRoutes = () => {
  const auth = useAuth()
  const financierId = auth?.user?.profile?.sub
  return (
    <Routes>
      <Route path='financier/:financierId/*' element={<FinancierPagesWrapper />}>
        <Route index path='pipeline' element={<Pipeline />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='calls' element={<Calls />} />
        <Route path='calls/:callId' element={<CallsDetailsPage />} />
        <Route path='calls/:callId/applications/:stage/:applicationIndex' element={<CandidatesDetailPage />} />
        <Route path='deals' element={<Deals />} />
        <Route path='settings' element={<Settings />} />
      </Route>
      <Route path='/' element={<Navigate to={`/financier/${financierId}/pipeline`} />} />
      <Route path='auth/*' element={<Navigate to={`/financier/${financierId}/pipeline`} />} />
    </Routes>
  )
}

export default FinancierRoutes