import { FC } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getInitials } from '../../../../utils/stringHelpers'
import { useSelector } from 'react-redux'
import { timeAgo } from '../../../../utils/dateHelpers'
import { createCompanySelectors } from '../CompaniesApiSlice'
import { RootState } from '../../../../data/redux/Store'
import CardInitials from '../../shared/components/CardInitials'
import CardBadge from '../../shared/components/CardBadge'
import CardDetail from '../../shared/components/CardDetail'
import ProgressBar from '../../shared/components/ProgressBar'
import { INameIdPair } from '../../shared/interfaces'
import { IListPageFilters } from '../interfaces'
import { Col, Row } from 'react-bootstrap'

type CompanyCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
  filters: IListPageFilters
  includeOnboardingStage?: boolean
  showCompanyCode?: boolean
  includeFinancingAsk?: boolean
  linkToCompanies?: boolean
  showCardIcons?: boolean
}

export const CompanyCard: FC<CompanyCardProps> = ({
  id,
  page,
  pageSize,
  searchTerm,
  filters,
  linkToCompanies = true,
  includeOnboardingStage = true,
  showCompanyCode = false,
  includeFinancingAsk = false,
  showCardIcons = false,
}) => {
  const { selectById: selectCompanyById } = createCompanySelectors({
    page,
    pageSize,
    searchTerm,
    ...filters,
  })
  const company = useSelector((state: RootState) => selectCompanyById(state, id))
  const checklistValues = company?.onboardingStatus.split(',').map(Number)
  const completedSteps = checklistValues?.filter((value) => value === 1).length
  const companyInitials = getInitials(company?.name, 3)

  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(`/companies/${company?.id}`, {
      state: { currentPage: page, pageSize },
    })
  }

  return (
    <div className='col-md-6 col-lg-4 col-sm-12 col-xl-4 min-height-200px pb-6'>
      <div
        onClick={handleNavigate}
        className='card border border-2 border-gray-300 mb-1 h-100 d-flex flex-column justify-content-between'
        style={{ cursor: 'pointer'}}
      >
        <div className='d-flex border-0 pt-2 px-5 justify-content-between align-items-center'>
          <div className='col-6'>
            <span
              className='fw-bolder text-dark fs-6 text-truncate'
              style={{
                maxWidth: '100%',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {showCompanyCode ? company?.code.toUpperCase() ?? '' : company?.name ?? ''}
            </span>
          </div>
          <div className='d-flex py-4 text-truncate' style={{
                maxWidth: '100%',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
            <CardBadge text={(company?.sectors?.[0]?.name ?? '').toUpperCase()} />
          </div>
        </div>
        <Row className={`px-5`}>
          <Col className='pe-2'>
            <CardDetail
              title='Years of Operation'
              showYearOfOperationIcon={showCardIcons}
              value={company?.yearOfIncorporation ? timeAgo(company?.yearOfIncorporation) : '-'}
            />
          </Col>
          <Col className='ps-2'>
            <CardDetail
              showLocationIcon={showCardIcons}
              title='Location'
              value={`${company?.country}, ${company?.region}`}
            />
          </Col>
        </Row>

        <Row className={`px-5 mb-2`}>
          <Col>
            <ProgressBar
              value={completedSteps || 0}
              total={6}
              title='Onboarding Stage'
              bold={true}
            />
          </Col>
        </Row>

        <Row className={`ps-7 ${includeFinancingAsk ? 'h-60px' : 'h-30px'}`}>
          {(company?.financingTypes ?? []).map((type: INameIdPair, index: number) => (
            <Col key={index} className='mb-2 flex-grow-0 px-1'>
              <CardBadge text={includeFinancingAsk ? `${type.name}` : type.name} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}
