import {useState, FC} from 'react'
import Search from '../../shared/components/Search'
import {
  useAddNewFinancierDocumentFolderMutation,
  useDeleteFinancierDocumentFolderMutation,
  useGetFinancierDocumentFoldersQuery,
  useUpdateFinancierDocumentFolderMutation,
} from '../FinanciersApiSlice'
import {useParams} from 'react-router-dom'
import {formatBytes} from '../../../../utils/numberHelpers'
import Loading from '../../shared/components/Loading'
import {IFinancierDocumentFolders} from '../interfaces'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import {UpdateFolder} from '../../shared/components/UpdateFolder'
import {DeleteModal} from '../../shared/components/DeleteModal'
import { CreateFolder } from '../../shared/modals/CreateFolder'

type FinancierDocumentFolderCardProps = {
  handleFolderToggle: () => void
  setSelectedFolder: (folder: {index: number; id: string; folderName: string}) => void
  showModal: boolean
  handleClose: () => void
  selectedFolder: {index: number; id: string; folderName: string}
}
export const FinancierDocumentFolderCard: FC<FinancierDocumentFolderCardProps> = ({
  handleFolderToggle,
  setSelectedFolder,
  showModal,
  handleClose,
  selectedFolder,
}) => {
  const {id} = useParams<{id: string}>() as {id: string}

  const [searchTerm, setSearchTerm] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showFolderOptionsPopUp, setShowFolderOptionsPopUp] = useState<boolean>(false)
  const {data: financierDocumentFoldersState, isFetching} = useGetFinancierDocumentFoldersQuery({id,searchTerm})
  const [updateFolder, {isLoading: updatingFolder}] = useUpdateFinancierDocumentFolderMutation()
  const [deleteFolder, {isLoading: deletingFolder}] = useDeleteFinancierDocumentFolderMutation()
  const [addFolder, {isLoading: creatingFInancierFolder}] = useAddNewFinancierDocumentFolderMutation()
  const financierDocumentFolders: IFinancierDocumentFolders[] = Object.values(
    financierDocumentFoldersState?.entities?.entities || {}
  ) as IFinancierDocumentFolders[]

  const handleFolderOptionsPopup = () => {
    setShowFolderOptionsPopUp(!showFolderOptionsPopUp)
  }
  const handleFolderOptionsClick = (index: number, id: string, folderName: string) => {
    if (selectedFolder.folderName === '') {
      setSelectedFolder({index, id, folderName})
    }
  }
  const handleFolderClick = (index: number, id: string, folderName: string) => {
    handleFolderToggle()
    setSelectedFolder({index, id, folderName})
  }
  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
    if (showDeleteModal && showFolderOptionsPopUp) {
      handleFolderOptionsPopup()
    }
  }
  const handleEditModal = () => {
    setShowEditModal(!showEditModal)
  }

  const handleDelete = async () => {
    try {
      await deleteFolder(selectedFolder.id).unwrap()
      setSelectedFolder({index: 0, id: '', folderName: ''})
      handleFolderOptionsPopup()
      handleDeleteModal()
    } catch (error) {}
  }

  const handleUpdateFolder = async (name: string) => {
    try {
      await updateFolder({
        name,
        financier: id,
        id: selectedFolder.id,
      }).unwrap()
      setSelectedFolder({index: 0, id: '', folderName: ''})
      handleFolderOptionsPopup()
      handleEditModal()
    } catch (error) {}
  }
  const handleCreateFolder = async (name: string) => {
    try {
      await addFolder({
        name,
        financier: id,
      }).unwrap()
      handleClose()
    } catch (error) {}
  }
  

  return (
    <>
      <div className='d-flex align-items-center justify-content-between position-relative py-2 px-6'>
        <div>
          <h6>Financier Documents</h6>
        </div>
        <div className='d-flex align-items-center gap-3'>
          <Search setSearchTerm={setSearchTerm} />
          <button
            type='button'
            className='btn btn-danger d-flex align-items-center justify-content-center btn-sm'
            onClick={handleClose}
          >
            <i className='fas fa-plus fs-5 me-1'></i>
            <span className='ms-1'>New Folder</span>
          </button>
        </div>
      </div>
      <div className='row'>
        {isFetching ? (
          <div className='d-flex justify-content-center'>
            <Loading />
          </div>
        ) : (
          financierDocumentFolders.map((folder, index) => (
            <div key={index} className='col-md-6 col-lg-6 col-sm-12 mt-5 min-height-200px'>
              <div className='card border border-gray-300 mb-5 px-5 cursor-pointer h-100 d-flex flex-column justify-content-between'>
                <div className='d-flex border-0 pt-5 justify-content-between align-items-center'>
                  <i className={`fa fa-folder text-warning fs-2x me-2`}></i>
                  <div className='position-relative flex-grow-1'>
                    <div
                      className='d-flex flex-grow-1 justify-content-end p-2 cursor-pointer'
                      onClick={() => handleFolderOptionsClick(index, folder.id, folder.folderName)}
                    >
                      <OptionsPopup
                        selectedEntity={selectedFolder}
                        setSelectedEntity={setSelectedFolder}
                        handleDelete={handleDelete}
                        handleUpdateEntity={handleUpdateFolder}
                        updatingEntity={updatingFolder}
                        showDeleteModal={showDeleteModal}
                        showEditModal={showEditModal}
                        deletingEntity={deletingFolder}
                        handleDeleteModal={handleDeleteModal}
                        handleEditModal={handleEditModal}
                        entity='Folder'
                        includeFilePreviewOption={false}
                        includeUpdateOption={true}
                        includeDeleteOption={true}
                        isEllipsis={true}
                      />
                    </div>
                  </div>
                </div>
                  <div className='flex-grow-1 pt-7' onClick={() => handleFolderClick(index, folder.id, folder.folderName)}>
                    <span className='fw-bolder text-dark fs-4 lh-1'>{folder.folderName}</span>
                  </div>
                <div onClick={() => handleFolderClick(index, folder.id, folder.folderName)} >
                  <div className={'mb-5 d-flex justify-content-between pt-6'}>
                    <span className='fw-bold text-dark fs-5'>{`${folder.fileCount} Files`}</span>
                    <span className='fw-bolder text-dark fs-5'>
                      {formatBytes(folder.totalFileSize)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <CreateFolder
        showCreateFolderModal={showModal}
        handleCreateFolderModal={handleClose}
        handleCreateFolder={handleCreateFolder}
        isLoading={creatingFInancierFolder}
      />
      <UpdateFolder
          showEditModal={showEditModal}
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          handleEditModal={handleEditModal }
          isLoading={updatingFolder}
          handleUpdateFolder={handleUpdateFolder}
        />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        isLoading={deletingFolder}
        handleDelete={handleDelete}
        item={'File'}
        handleClose={handleDeleteModal}
      />
    </>
  )
}
