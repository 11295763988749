import React, {useEffect} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_theme/helpers'
import {ErrorsPageHeaders} from './ErrorsPageHeaders'
import {useAuth} from 'react-oidc-context'

const ErrorsPage: React.FC = () => {
  const {errorCode} = useParams<{errorCode: string}>()
  const navigate = useNavigate()
  const auth = useAuth()

  const errorCodeNumber = errorCode ? parseInt(errorCode) : undefined

  const renderErrorContent = () => {
    switch (errorCodeNumber) {
      case 403:
        return {
          title: 'Access is denied',
          message: 'You do not have permission to access this page.',
          image: toAbsoluteUrl('/media/images/restrictions.png'),
          buttonText: 'Go to Home',
          iconClass: 'bi bi-house-door-fill',
          onClick: () => navigate('/'),
        }
      case 404:
        return {
          title: 'Page Not Found',
          message:
            'The page you are looking for might have been removed or is temporarily unavailable.',
          image: toAbsoluteUrl('/media/images/no-results.png'),
          buttonText: 'Go to Home',
          iconClass: 'bi bi-house-door-fill',
          onClick: () => navigate('/'),
        }
      case 401:
        return {
          title: 'Session has expired',
          message: 'Your session has expired. Please log in again.',
          image: toAbsoluteUrl('/media/images/expired.png'),
          buttonText: 'Go to Login',
          iconClass: 'bi bi-box-arrow-in-right',
          onClick: async () => {
            await handleLogout()
            navigate('/auth')
          },
        }
      case 400:
        return {
          title: 'Bad Request',
          message: 'It looks like there was an issue with your request.',
          image: toAbsoluteUrl('/media/images/page-under-maintenance.svg'),
          buttonText: 'Go to Home',
          iconClass: 'bi bi-box-arrow-in-right',
          onClick: () => navigate('/'),
        }
      default:
        return {
          title: 'Page Under Maintenance',
          message:
            'We apologize for the inconvenience, but this page is currently undergoing maintenance.',
          image: toAbsoluteUrl('/media/images/page-under-maintenance.svg'),
          buttonText: 'Reload Page...',
          iconClass: 'bi bi-arrow-clockwise',
          onClick: () => navigate(-1),
        }
    }
  }

  async function handleLogout() {
    try {
      // await auth.signoutSilent()
      await auth.signoutRedirect()
    } catch (error) {}
  }

  const {title, message, image, buttonText, iconClass, onClick} = renderErrorContent()

  return (
    <div className='d-flex flex-column h-100'>
      <ErrorsPageHeaders />
      <div className='bg-white flex-grow-1'>
        <div className='d-flex flex-column justify-content-center align-items-center mt-180px'>
          <img src={image} alt={title} style={{width: '90px', height: 'auto'}} />
          <h1 className='mt-3'>{title}</h1>
          <p className='text-center w-50'>{message}</p>
          <button
            className='btn btn-sm ms-lg-3 btn-danger d-flex align-items-center justify-content-center'
            onClick={onClick}
          >
            <i className={`${iconClass} me-2 fs-2`}></i> {buttonText}
          </button>
        </div>
      </div>
    </div>
  )
}

export {ErrorsPage}
