import {FC} from 'react'

type CardDetailProps = {
  title: string
  value: string
  showYearOfOperationIcon?: boolean
  showLocationIcon?: boolean
  IsCall? : boolean
}

const CardDetail: FC<CardDetailProps> = ({
                                           title,
                                           value,
                                           showLocationIcon = false,
                                           showYearOfOperationIcon = false,
                                           IsCall = false
                                         }) => {
  return (
    <div className={`border border-dashed rounded min-w-125px py-3 px-2 ${IsCall ? 'border-gray-400' : 'border-gray-600' }`}>
      <div className={` ${IsCall ? 'fs-8' : 'fs-9' } fw-bold text-gray-500`}>
        {showYearOfOperationIcon && <i className="fas fa-calendar-alt me-2"></i>}
        {showLocationIcon && <i className="fas fa-map-marker-alt me-2"></i>}
        {title}
      </div>
      <div className="fs-8 text-gray-800 fw-bolder text-truncate" style={{
              maxWidth: '150%',
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>{value}</div>
    </div>
  )
}

export default CardDetail
